// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicios-jsx": () => import("./../src/pages/servicios.jsx" /* webpackChunkName: "component---src-pages-servicios-jsx" */),
  "component---src-pages-servicios-aires-acondicionados-jsx": () => import("./../src/pages/servicios/AiresAcondicionados.jsx" /* webpackChunkName: "component---src-pages-servicios-aires-acondicionados-jsx" */),
  "component---src-pages-servicios-andenes-carga-jsx": () => import("./../src/pages/servicios/AndenesCarga.jsx" /* webpackChunkName: "component---src-pages-servicios-andenes-carga-jsx" */),
  "component---src-pages-servicios-carros-supermercado-jsx": () => import("./../src/pages/servicios/CarrosSupermercado.jsx" /* webpackChunkName: "component---src-pages-servicios-carros-supermercado-jsx" */),
  "component---src-pages-servicios-cavas-jsx": () => import("./../src/pages/servicios/Cavas.jsx" /* webpackChunkName: "component---src-pages-servicios-cavas-jsx" */),
  "component---src-pages-servicios-check-out-jsx": () => import("./../src/pages/servicios/CheckOut.jsx" /* webpackChunkName: "component---src-pages-servicios-check-out-jsx" */),
  "component---src-pages-servicios-equipamiento-gastronomico-jsx": () => import("./../src/pages/servicios/EquipamientoGastronomico.jsx" /* webpackChunkName: "component---src-pages-servicios-equipamiento-gastronomico-jsx" */),
  "component---src-pages-servicios-estanterias-jsx": () => import("./../src/pages/servicios/Estanterias.jsx" /* webpackChunkName: "component---src-pages-servicios-estanterias-jsx" */),
  "component---src-pages-servicios-exhibidoras-jsx": () => import("./../src/pages/servicios/Exhibidoras.jsx" /* webpackChunkName: "component---src-pages-servicios-exhibidoras-jsx" */),
  "component---src-pages-servicios-maquinas-hielo-jsx": () => import("./../src/pages/servicios/MaquinasHielo.jsx" /* webpackChunkName: "component---src-pages-servicios-maquinas-hielo-jsx" */),
  "component---src-pages-servicios-repuestos-jsx": () => import("./../src/pages/servicios/Repuestos.jsx" /* webpackChunkName: "component---src-pages-servicios-repuestos-jsx" */),
  "component---src-pages-servicios-sistema-refrigeracion-jsx": () => import("./../src/pages/servicios/SistemaRefrigeracion.jsx" /* webpackChunkName: "component---src-pages-servicios-sistema-refrigeracion-jsx" */)
}

